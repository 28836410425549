.logoIcon {
  position: relative;
  width: 63px;
  height: 27px;
  overflow: hidden;
  flex-shrink: 0;
}
.linkOne {
  position: relative;
  line-height: 150%;
}
.chevronDownIcon {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.navLinkDropdown {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-9xs);
}
.column,
.content1 {
  justify-content: flex-start;
}
.column {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: var(--gap-13xl);
}
.content1 {
  gap: var(--gap-5xl);
}
.actions,
.content,
.content1 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.actions {
  justify-content: center;
  gap: var(--gap-base);
}
.content {
  align-self: stretch;
  border-bottom: 1px solid var(--black);
  box-sizing: border-box;
  height: 72px;
  overflow: hidden;
  flex-shrink: 0;
  justify-content: space-between;
  padding: 0 var(--padding-45xl);
  text-align: left;
  font-size: var(--text-regular-bold-size);
  color: var(--black);
  font-family: var(--text-tiny-normal);
}
