.placeholderImageIcon {
  align-self: stretch;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.mediumLengthHero {
  align-self: stretch;
  position: relative;
  line-height: 120%;
}
.column {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.loremIpsumDolor {
  align-self: stretch;
  position: relative;
  line-height: 150%;
}
.actions,
.column1 {
  align-items: flex-start;
}
.actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: var(--padding-base) 0 0;
  gap: var(--gap-base);
}
.column1 {
  align-self: stretch;
  gap: var(--gap-5xs);
  font-size: var(--text-regular-bold-size);
}
.breakpointMobile,
.column1,
.content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.content {
  align-self: stretch;
  overflow: hidden;
  align-items: flex-start;
  padding: var(--padding-29xl) var(--padding-xl);
  gap: var(--gap-xl);
}
.breakpointMobile {
  background-color: var(--white);
  width: 375px;
  height: 812px;
  align-items: center;
  text-align: left;
  font-size: var(--heading-h1-mobile-size);
  color: var(--black);
  font-family: var(--text-tiny-normal);
}
