.heading,
.placeholderImageIcon {
  align-self: stretch;
  position: relative;
}
.heading {
  line-height: 120%;
}
.placeholderImageIcon {
  max-width: 100%;
  overflow: hidden;
  height: 198px;
  flex-shrink: 0;
  object-fit: cover;
}
.heading1,
.text {
  align-self: stretch;
  position: relative;
  line-height: 140%;
}
.text {
  font-size: var(--text-regular-bold-size);
  line-height: 150%;
}
.content1 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-xs);
}
.action,
.content,
.content1 {
  align-self: stretch;
  align-items: flex-start;
}
.action {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--padding-5xs) 0 0;
}
.content {
  gap: var(--gap-base);
}
.breakpointMobile,
.column,
.content,
.row {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.column {
  align-self: stretch;
  overflow: hidden;
  align-items: flex-start;
  gap: var(--gap-5xl);
}
.breakpointMobile,
.row {
  gap: var(--gap-29xl);
}
.row {
  align-self: stretch;
  align-items: center;
  font-size: var(--heading-h5-mobile-size);
}
.breakpointMobile {
  background-color: var(--white);
  width: 375px;
  overflow: hidden;
  flex-shrink: 0;
  align-items: flex-start;
  padding: var(--padding-45xl) var(--padding-xl);
  box-sizing: border-box;
  text-align: left;
  font-size: var(--heading-h3-mobile-size);
  color: var(--black);
  font-family: var(--text-tiny-normal);
}
