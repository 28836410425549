.heading,
.subheading,
.text {
  align-self: stretch;
  position: relative;
}
.subheading {
  line-height: 150%;
  font-weight: 600;
}
.heading,
.text {
  line-height: 120%;
}
.text {
  font-size: var(--text-regular-bold-size);
  line-height: 150%;
}
.content,
.sectionTitle {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.content {
  align-self: stretch;
  gap: var(--gap-xl);
  font-size: var(--heading-h2-mobile-size);
}
.sectionTitle {
  gap: var(--gap-xs);
}
.input,
.name,
.sectionTitle {
  align-self: stretch;
}
.name {
  position: relative;
  line-height: 150%;
}
.input {
  gap: var(--gap-5xs);
}
.breakpointMobile,
.form,
.input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.form {
  align-self: stretch;
  gap: var(--gap-5xl);
}
.breakpointMobile {
  background-color: var(--white);
  width: 375px;
  overflow: hidden;
  flex-shrink: 0;
  padding: var(--padding-45xl) var(--padding-xl);
  box-sizing: border-box;
  gap: var(--gap-13xl);
  text-align: left;
  font-size: var(--text-regular-bold-size);
  color: var(--black);
  font-family: var(--text-tiny-normal);
}
