.logoIcon {
  position: relative;
  width: 63px;
  height: 27px;
  overflow: hidden;
  flex-shrink: 0;
}
.column {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.columnOne,
.linkOne {
  position: relative;
  line-height: 150%;
}
.columnOne {
  align-self: stretch;
  font-weight: 600;
}
.linkOne {
  flex: 1;
}
.link {
  align-self: stretch;
  flex-direction: row;
  padding: var(--padding-5xs) 0;
}
.column1,
.footerLinks,
.link,
.links {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.footerLinks {
  align-self: stretch;
  flex-direction: column;
  font-size: var(--text-small-link-size);
}
.column1,
.links {
  flex: 1;
}
.column1 {
  overflow: hidden;
  flex-direction: column;
  gap: var(--gap-base);
}
.links {
  flex-direction: row;
  gap: var(--gap-21xl);
}
.joinOurNewsletter {
  align-self: stretch;
  position: relative;
  line-height: 150%;
}
.form,
.subscribeParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.form {
  flex-direction: row;
}
.privacyPolicy {
  text-decoration: underline;
}
.actions {
  align-self: stretch;
  flex-direction: column;
  gap: var(--gap-base);
  font-size: var(--text-tiny-normal-size);
}
.actions,
.content,
.newslatter {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.newslatter {
  width: 500px;
  flex-direction: column;
  gap: var(--gap-5xl);
}
.content {
  align-self: stretch;
  height: 248px;
  flex-direction: row;
  gap: var(--gap-109xl);
}
.divider {
  align-self: stretch;
  position: relative;
  background-color: var(--black);
  height: 1px;
}
.relumeAllRights,
.termsOfService {
  position: relative;
  line-height: 150%;
}
.termsOfService {
  text-decoration: underline;
}
.credits1,
.footerLinks3 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
}
.credits1 {
  align-items: center;
}
.iconFacebook {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.socialLinks {
  flex-direction: row;
  justify-content: flex-start;
  gap: var(--gap-xs);
}
.breakpointDesktop,
.credits,
.row,
.socialLinks {
  display: flex;
  align-items: flex-start;
}
.row {
  align-self: stretch;
  flex-direction: row;
  justify-content: space-between;
}
.breakpointDesktop,
.credits {
  flex-direction: column;
  justify-content: flex-start;
}
.credits {
  align-self: stretch;
  gap: var(--gap-13xl);
  font-size: var(--text-small-link-size);
}
.breakpointDesktop {
  background-color: var(--white);
  width: 1440px;
  overflow: hidden;
  flex-shrink: 0;
  padding: var(--padding-61xl) var(--padding-45xl);
  box-sizing: border-box;
  gap: var(--gap-61xl);
  text-align: left;
  font-size: var(--text-regular-bold-size);
  color: var(--black);
  font-family: var(--text-tiny-normal);
}
