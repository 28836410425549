.faqs,
.text {
  align-self: stretch;
  position: relative;
  line-height: 120%;
}
.text {
  font-size: var(--text-regular-bold-size);
  line-height: 150%;
}
.content,
.sectionTitle {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
}
.sectionTitle {
  gap: var(--gap-5xl);
}
.divider {
  align-self: stretch;
  background-color: var(--black);
  height: 1px;
}
.divider,
.icon,
.question {
  position: relative;
}
.question {
  flex: 1;
  line-height: 150%;
}
.icon {
  width: 32px;
  height: 32px;
  overflow: hidden;
  flex-shrink: 0;
}
.accordionItem,
.title {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}
.title {
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  padding: var(--padding-base) 0;
  gap: var(--gap-xs);
}
.accordionItem {
  flex-direction: column;
  align-items: flex-start;
}
.accordion {
  align-self: stretch;
  font-size: var(--text-regular-bold-size);
}
.accordion,
.breakpointMobile,
.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.container {
  align-self: stretch;
  gap: var(--gap-29xl);
}
.breakpointMobile {
  background-color: var(--white);
  width: 375px;
  overflow: hidden;
  flex-shrink: 0;
  padding: var(--padding-45xl) var(--padding-xl);
  box-sizing: border-box;
  text-align: left;
  font-size: var(--heading-h2-mobile-size);
  color: var(--black);
  font-family: var(--text-tiny-normal);
}
