@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;600;700&display=swap");
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --text-tiny-normal: Roboto;

  /* font sizes */
  --text-small-link-size: 14px;
  --text-tiny-normal-size: 12px;
  --text-regular-bold-size: 16px;
  --heading-h2-mobile-size: 36px;
  --heading-h6-mobile-size: 18px;
  --heading-h4-mobile-size: 24px;
  --heading-h1-mobile-size: 40px;
  --heading-h3-mobile-size: 32px;
  --heading-h5-mobile-size: 20px;
  --heading-h2-size: 48px;
  --heading-h1-size: 56px;

  /* Colors */
  --white: #fff;
  --black: #000;
  --light-grey: #f4f4f4;

  /* Gaps */
  --gap-29xl: 48px;
  --gap-5xl: 24px;
  --gap-13xl: 32px;
  --gap-base: 16px;
  --gap-xs: 12px;
  --gap-21xl: 40px;
  --gap-xl: 20px;
  --gap-5xs: 8px;
  --gap-3xs: 10px;
  --gap-9xs: 4px;
  --gap-61xl: 80px;
  --gap-109xl: 128px;

  /* Paddings */
  --padding-29xl: 48px;
  --padding-xl: 20px;
  --padding-base: 16px;
  --padding-xs: 12px;
  --padding-5xl: 24px;
  --padding-5xs: 8px;
  --padding-13xl: 32px;
  --padding-45xl: 64px;
  --padding-61xl: 80px;
  --padding-9xs: 4px;
  --padding-93xl: 112px;
  --padding-77xl: 96px;
}
