.placeholder {
  flex: 1;
  position: relative;
  line-height: 150%;
  background: linear-gradient(#000, #000), #505050;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.textInput {
  background-color: var(--white);
  border: 1px solid var(--black);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-xs);
  text-align: left;
  font-size: var(--text-regular-bold-size);
  font-family: var(--text-tiny-normal);
  align-self: stretch;
}
