.heading,
.subheading,
.text {
  align-self: stretch;
  position: relative;
}
.subheading {
  line-height: 150%;
  font-weight: 600;
}
.heading,
.text {
  line-height: 120%;
}
.text {
  font-size: var(--text-regular-bold-size);
  line-height: 150%;
}
.content,
.sectionTitle {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.content {
  gap: var(--gap-xl);
  font-size: var(--heading-h2-mobile-size);
}
.sectionTitle {
  gap: var(--gap-xs);
}
.iconEnvelope {
  position: relative;
  width: 32px;
  height: 32px;
  overflow: hidden;
  flex-shrink: 0;
}
.heading1,
.link,
.text1 {
  align-self: stretch;
  position: relative;
}
.heading1 {
  font-size: var(--heading-h6-mobile-size);
  line-height: 140%;
}
.link,
.text1 {
  line-height: 150%;
}
.link {
  text-decoration: underline;
}
.contactInfo {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.heading3 {
  align-self: stretch;
  position: relative;
  line-height: 140%;
}
.actions {
  padding: var(--padding-base) 0 0;
}
.actions,
.content4,
.row {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.content4 {
  gap: var(--gap-xs);
  font-size: var(--heading-h6-mobile-size);
}
.row {
  gap: var(--gap-21xl);
}
.placeholderImageIcon {
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 400px;
  flex-shrink: 0;
  object-fit: cover;
}
.breakpointMobile,
.content1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-29xl);
}
.content1 {
  align-self: stretch;
}
.breakpointMobile {
  background-color: var(--white);
  width: 375px;
  overflow: hidden;
  flex-shrink: 0;
  padding: var(--padding-45xl) var(--padding-xl);
  box-sizing: border-box;
  text-align: left;
  font-size: var(--text-regular-bold-size);
  color: var(--black);
  font-family: var(--text-tiny-normal);
}
