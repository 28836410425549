.heading {
  align-self: stretch;
  position: relative;
  line-height: 150%;
}
.relumeBlack,
.webflowBlack {
  position: relative;
  width: 120px;
  height: 48px;
  overflow: hidden;
  flex-shrink: 0;
}
.relumeBlack {
  width: 140px;
  height: 56px;
}
.column {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-13xl);
}
.breakpointMobile,
.content,
.row {
  display: flex;
  justify-content: flex-start;
  gap: var(--gap-13xl);
}
.row {
  width: 335px;
  flex-direction: row;
  align-items: flex-start;
}
.breakpointMobile,
.content {
  flex-direction: column;
  align-items: center;
  padding: var(--padding-base) 0 var(--padding-5xs);
}
.breakpointMobile {
  background-color: var(--white);
  width: 375px;
  overflow: hidden;
  flex-shrink: 0;
  padding: var(--padding-13xl) var(--padding-xl);
  box-sizing: border-box;
  gap: var(--gap-5xl);
  text-align: center;
  font-size: var(--text-regular-bold-size);
  color: var(--black);
  font-family: var(--text-tiny-normal);
}
