.typeYourMessage {
  flex: 1;
  position: relative;
  line-height: 150%;
  background: linear-gradient(#000, #000), #505050;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  z-index: 0;
}
.textAreaChild,
.textAreaItem {
  position: absolute;
  margin: 0 !important;
  right: 3.29px;
  bottom: 3.05px;
  width: 2.71px;
  height: 2.71px;
  z-index: 1;
}
.textAreaItem {
  bottom: 3.29px;
  width: 6.71px;
  height: 6.71px;
  z-index: 2;
}
.textArea {
  background-color: var(--white);
  border: 1px solid var(--black);
  box-sizing: border-box;
  height: 182px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-xs);
  position: relative;
  text-align: left;
  font-size: var(--text-regular-bold-size);
  font-family: var(--text-tiny-normal);
  align-self: stretch;
}
