.logoIcon {
  position: relative;
  width: 63px;
  height: 27px;
  overflow: hidden;
  flex-shrink: 0;
}
.content1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.iconX {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.content,
.icon {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.icon {
  width: 48px;
  height: 48px;
  justify-content: center;
}
.content {
  align-self: stretch;
  height: 72px;
  overflow: hidden;
  flex-shrink: 0;
  justify-content: space-between;
  padding: 0 var(--padding-xs) 0 var(--padding-xl);
  box-sizing: border-box;
}
.linkOne {
  flex: 1;
  position: relative;
  line-height: 150%;
}
.link,
.navLinkDropdown {
  display: flex;
  flex-direction: row;
}
.link {
  align-self: stretch;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-xs) 0;
}
.navLinkDropdown {
  flex: 1;
  align-items: center;
  justify-content: center;
  gap: var(--gap-9xs);
}
.pageGroupOne {
  font-size: var(--text-small-link-size);
  font-weight: 600;
}
.loremIpsumDolor,
.pageGroupOne,
.pageOne {
  align-self: stretch;
  position: relative;
  line-height: 150%;
}
.pageOne {
  font-weight: 600;
}
.loremIpsumDolor {
  font-size: var(--text-small-link-size);
  display: none;
}
.content2,
.menu,
.menuItem,
.menuList {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.content2 {
  flex: 1;
  flex-direction: column;
}
.menu,
.menuItem,
.menuList {
  align-self: stretch;
}
.menuItem {
  flex-direction: row;
  padding: var(--padding-5xs) 0;
  gap: var(--gap-xs);
}
.menu,
.menuList {
  flex-direction: column;
  gap: var(--gap-5xs);
}
.menu {
  padding: var(--padding-base) 0;
  gap: var(--gap-5xl);
}
.placeholderImageIcon {
  position: relative;
  width: 287px;
  height: 188.34px;
  object-fit: cover;
}
.loremIpsumDolor12 {
  align-self: stretch;
  position: relative;
  font-size: var(--text-small-link-size);
  line-height: 150%;
}
.content15 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.readMore {
  position: relative;
  font-size: var(--text-small-link-size);
  text-decoration: underline;
  line-height: 150%;
}
.blogItem,
.content14 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.content14 {
  width: 288px;
  gap: var(--gap-5xs);
}
.blogItem {
  align-self: stretch;
  padding: var(--padding-5xs) 0;
  gap: var(--gap-base);
}
.seeAllArticles {
  position: relative;
  line-height: 150%;
}
.chevronDownIcon1 {
  position: relative;
  width: 16px;
  height: 16px;
  overflow: hidden;
  flex-shrink: 0;
}
.button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-9xs) 0;
  gap: var(--gap-9xs);
}
.blog,
.megaMenu {
  align-self: stretch;
  align-items: flex-start;
}
.blog {
  background-color: var(--light-grey);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--padding-5xl);
  gap: var(--gap-base);
}
.megaMenu {
  overflow: hidden;
  font-size: var(--text-regular-bold-size);
}
.megaMenu,
.row1 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.actions,
.column,
.row1 {
  align-self: stretch;
  align-items: flex-start;
}
.column {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.actions {
  padding: var(--padding-5xl) 0 var(--padding-61xl);
  gap: var(--gap-base);
}
.actions,
.breakpointMobile,
.row {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.row {
  align-self: stretch;
  align-items: flex-start;
  padding: var(--padding-base) var(--padding-xl) var(--padding-61xl);
  gap: var(--gap-3xs);
}
.breakpointMobile {
  background-color: var(--white);
  width: 375px;
  overflow: hidden;
  flex-shrink: 0;
  align-items: center;
  text-align: left;
  font-size: var(--heading-h6-mobile-size);
  color: var(--black);
  font-family: var(--text-tiny-normal);
}
