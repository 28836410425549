.button {
  position: relative;
  line-height: 150%;
}
.stylesecondarySmallfalse {
  border: 1px solid var(--black);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-xs) var(--padding-5xl);
  text-align: left;
  font-size: var(--text-regular-bold-size);
  color: var(--black);
  font-family: var(--text-tiny-normal);
}
