.heading,
.subheading,
.text {
  align-self: stretch;
  position: relative;
}
.subheading {
  line-height: 150%;
  font-weight: 600;
}
.heading,
.text {
  line-height: 120%;
}
.text {
  font-size: var(--text-regular-bold-size);
  line-height: 150%;
}
.content1,
.sectionTitle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.content1 {
  align-self: stretch;
  gap: var(--gap-xl);
  font-size: var(--heading-h2-mobile-size);
}
.sectionTitle {
  width: 335px;
  gap: var(--gap-xs);
}
.monthly {
  position: relative;
  line-height: 150%;
}
.button,
.button1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xs) var(--padding-5xl);
}
.button {
  background-color: var(--black);
}
.button1 {
  border: 1px solid var(--black);
  color: var(--black);
}
.content,
.tabs {
  display: flex;
  justify-content: flex-start;
}
.tabs {
  flex-direction: row;
  align-items: flex-start;
  text-align: left;
  color: var(--white);
}
.content {
  flex-direction: column;
  align-items: center;
  gap: var(--gap-13xl);
}
.heading1,
.price1,
.price2 {
  position: relative;
  line-height: 140%;
}
.price1,
.price2 {
  line-height: 120%;
}
.price2 {
  font-size: var(--heading-h4-mobile-size);
  line-height: 140%;
}
.container,
.price {
  align-self: stretch;
  display: flex;
}
.container {
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  font-size: var(--heading-h1-mobile-size);
}
.price {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.checkIcon {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.column,
.content3,
.list,
.listItem {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}
.listItem {
  flex-direction: row;
  align-items: flex-start;
  gap: var(--gap-base);
}
.column,
.content3,
.list {
  flex-direction: column;
}
.list {
  align-items: flex-start;
  padding: var(--padding-5xs) 0;
  gap: var(--gap-base);
  text-align: left;
  font-size: var(--text-regular-bold-size);
}
.column,
.content3 {
  align-items: center;
  gap: var(--gap-5xl);
}
.column {
  border: 1px solid var(--black);
  padding: var(--padding-13xl) var(--padding-5xl);
  gap: var(--gap-13xl);
}
.container1 {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  font-size: var(--heading-h1-mobile-size);
}
.breakpointMobile,
.content2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.content2 {
  align-self: stretch;
  gap: var(--gap-13xl);
  font-size: var(--heading-h6-mobile-size);
}
.breakpointMobile {
  background-color: var(--white);
  width: 375px;
  overflow: hidden;
  flex-shrink: 0;
  padding: var(--padding-45xl) var(--padding-xl);
  box-sizing: border-box;
  gap: var(--gap-29xl);
  text-align: center;
  font-size: var(--text-regular-bold-size);
  color: var(--black);
  font-family: var(--text-tiny-normal);
}
