.checkbox {
  position: relative;
  background-color: var(--white);
  border: 1px solid var(--black);
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.label {
  position: relative;
  line-height: 150%;
}
.selectedfalse {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xs);
  text-align: left;
  font-size: var(--text-regular-bold-size);
  color: var(--black);
  font-family: var(--text-tiny-normal);
}
