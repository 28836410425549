.button {
  position: relative;
  line-height: 150%;
}
.iconRelume {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.stylelinkSmallfalseDark {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
  text-align: left;
  font-size: var(--text-regular-bold-size);
  color: var(--black);
  font-family: var(--text-tiny-normal);
}
