.heading {
  position: relative;
  line-height: 150%;
  display: inline-block;
  width: 560px;
}
.relumeBlack,
.webflowBlack {
  position: relative;
  width: 120px;
  height: 48px;
  overflow: hidden;
  flex-shrink: 0;
}
.relumeBlack {
  width: 140px;
  height: 56px;
}
.row {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-29xl);
}
.breakpointDesktop,
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--gap-5xl);
}
.content {
  align-self: stretch;
  justify-content: center;
}
.breakpointDesktop {
  background-color: var(--white);
  width: 1440px;
  overflow: hidden;
  flex-shrink: 0;
  justify-content: flex-start;
  padding: var(--padding-61xl) var(--padding-45xl);
  box-sizing: border-box;
  text-align: center;
  font-size: var(--heading-h6-mobile-size);
  color: var(--black);
  font-family: var(--text-tiny-normal);
}
